.react-grid-Row{
    height: 20px;
}

.react-grid-Main{
    font-family: Roboto, sans-serif;
    font-size: 14px;
}

input.form-control.input-sm {
    width: 80%!important;
    font-size: 15px!important;
}

.react-grid-HeaderCell {
    font-size: 15px;
    overflow: hidden!important;
    height: 30px!important;
    padding: 8px 0 0 0;
    border: 0;
    text-align: left;
}

.react-grid-HeaderCell>div {
    width: 80%;
}

.react-grid-Cell {
    text-align: left;
}

.Pagination-pagination-443 {
    float: left!important;
}

.PageSizeSelector-pageSizeSelector-450 {
    float: left!important;
}

td.MuiTableCell-body>div {
    background-color: transparent!important;
}
