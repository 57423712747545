body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: transparent;
  font-size: 16px;
  -webkit-text-fill-color: #2c8fff;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

.custom-tooltip {
  background-color: #282C34;
  color: white;
  box-shadow: 0 0 5px -1px;
  padding: .5rem;
  border-radius: 6px;
  min-width: 150px;
}

.doka--root .doka--button-action-confirm {
  padding: 6px 16px;
  color: white;
  background-color: green !important;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.doka--root .doka--button-action-confirm:hover {
  background-color: rgba(0,128,0,.8) !important;
}
